.select-type-file__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    strong {
        color: #00254A;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width:265px
    }
    img {
        margin-left: 104px;
        margin-bottom: 18px;
    }
}

.select-type-file__container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 22px;
    .select-type-file__container--bootom {
        display: flex;
        padding: 8px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        border-radius: 8px;
        border: 1px solid var(--escala-de-grises-gris-500, #8D94A4);
        height:96px;
        &:hover{
            cursor: pointer;
        }
    }
}