.container_confirmation-date-expire {
   display: flex;
   flex-direction: column;
   gap: 32px;
   align-items: center;  
   margin-top: 48px;
   width: 100%; 
   padding-left: 16px;
   padding-right: 16px;
   padding-top: 34px;
   min-height: calc(90vh - 120px);
 h3 {
   color: #00254A;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }
 p {
    color: #00254A;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  b {
    color: #00254A;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
 }

}