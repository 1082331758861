*,
html,
body {
  font-family: 'Roboto', sans-serif;
}

html {
  height: 100vh;
  width: 100%;
  padding-right: 0px !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  overflow-y: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  width: inherit;
  height: 100%;
  width: auto;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

.modal-open {
  padding-right: 0px !important;

  .show {
    padding-right: 0px !important;
  }
}


@import "node_modules/bootstrap/scss/bootstrap";
@import './infraestructure/styles/colors.scss';
@import './infraestructure/styles/accordion.scss';
@import "../node_modules/@identy/identy-ocr/dist/identy-ocr-style.css";

.justify-self-right {
  justify-self: right;
}

.align-self-center {
  align-self: center !important;
}

.place-content-center {
  place-content: center;
}

.min-height-auto {
  min-height: auto !important;
}

hr {
  border: 1px solid var(--hr-light-gray);
  opacity: 0.2;
}

header {
  box-sizing: content-box;
}

.mb-3_2 {
  margin-bottom: 18px;
}

.mb-4_2 {
  margin-bottom: 30px;
}

.mb-4_3 {
  margin-bottom: 32px;
}

.mb-4_9 {
  margin-bottom: 40px;
}

.mt-4_3 {
  margin-top: 32px;
}

.w-32 {
  width: 32px;
}

.h-32 {
  height: 32px;
}

.content-container {
  min-height: calc(100vh - 115px);
}

.footer--pin {
  position: relative;
  bottom: 0 !important;
  padding: 0rem 1rem 1rem 1rem !important;
}

.progressBar {
  p {
    font-size: 14px;
  }

  .progress {
    margin-top: 4px;
    height: 4px !important;
    background-color: var(--dark-blue-title);

    .progress-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      color: var(--bs-progress-bar-color);
      text-align: center;
      white-space: nowrap;
      background-color: var(--btn-bg-primary) !important;
      transition: var(--bs-progress-bar-transition);
      height: 6px;
    }
  }
}

.general-input {
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  min-height: 0px !important;
  padding: 0rem 0.75rem !important;
}

.form-check-input {
  width: 24.5px;
  height: 24.5px;
  border: 2px solid var(--dark-blue-title);
  margin-top: 0px !important;

  &:checked {
    border-color: transparent;
    background-color: var(--dark-blue-title);
  }
   > input[type=checkbox]:disabled{
    color: var(--color-disabled-btn) !important;
    background-color: var(--bg-color-disabled-btn) !important;
  }
}

.form-check {
  display: block;

  label {
    display: flex;
    margin-left: 10px;
  }
}

.fs-7 {
  font-size: 14px;
}

.fs-8 {
  font-size: 17px;
}

.fs-9 {
  font-size: 18px;
}

.fs-10 {
  font-size: 20px;
}

.img-capture-ine {
  width: 220px;
}

.border-dangerous-red {
  border: 2px solid var(--dangerous-red);
}

.border-none {
  border: none;
}

.button-disabled {
  color: var(--color-disabled-btn);
  background-color: var(--bg-color-disabled-btn);
}

.button-enabled {
  color: var(--color-enabled-btn);
  background-color: var(--bg-color-enabled-btn);
}

button:disabled,
button[disabled] {
  color: var(--color-disabled-btn) !important;
  background-color: var(--bg-color-disabled-btn) !important;
}

.text-justify {
  text-align: justify;
}

.lh-20 {
  line-height: 20px;
}

.lh-24 {
  line-height: 24px;
}

.my-4_2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-4_3 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.justify-items-center {
  justify-items: center;
}

.left-auto {
  left: auto !important;
}

.signature-canvas {
  width: 90%;
  height: 700px;
  border: 1.8px solid black;
}

.signature-image {
  display: block;
  max-width: 100%;
  max-height: 500px;
  margin: 0 auto;
  border: 1px solid black;
  padding: 2rem;
}

.select-form-error {
  border-radius: 4px;
  border: 2px solid var(--m-3-sys-light-error, #B3261E);
  background: #FFF;
}

.accordion-button-arrow-done {
  &::after {
    display: none;
  }
}

.accordion-button-actions {
  background-color: transparent;

  span {
    &.accordion-button-arrow-red {
      color: #C94444;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px
    }

    &.accordion-button-arrow-blue {
      color: var(--color-secundario-azul-700, #005CB9);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 125% */
    }
  }
}

.container_form {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 34px;
  min-height: calc(78vh - 115px);
}

.pl-calc-msg-error {
  padding-left: calc(var(--bs-gutter-x) * 0.3);
}