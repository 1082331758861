.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    transform: scale(.85) translateY(-1.8rem) translateX(.15rem);
    background: white;
    color: rgba(var(--bs-body-color-rgb), 1);
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 1;
    background: none;
}

.form-floating>.form-select:disabled~label::after {
    background-color: white !important;
}

.form-select {
    &:disabled {
        color: var(--disabled-link);
        background-color: transparent !important;
    }
}

.radioToggleButton {
    display: inline-grid;

    input {
        visibility: hidden;
    }

    label {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 8px;
        border-radius: 8px;
        color: rgb(0, 92, 185);
        border: 2px solid rgb(218, 218, 218);
    }

    input:checked+label {
        background: var(--bg-toggle-btn-light-blue);
    }
}

.dynamic-style-button {
    display: inline-grid;

    input {
        visibility: hidden;
    }

    label {
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding: 8px;
        border-radius: 8px;
        color: rgb(0, 74, 148);
        border: 2px solid rgb(218, 218, 218);
        border-color: rgb(0, 74, 148);
        font: 16px;
    }

    input:checked+label {
        background: var(--bg-toggle-btn-light-blue);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}