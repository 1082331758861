.otpContainer {
  display: flex;
  column-gap: 10px;

  .divClass {
    background: var(--bg-toggle-btn-light-gray);
    width: 50px;
    height: 64px;
    //transform: rotate(45deg); uncomment to change to diamoind input
    text-align: center;
    border-radius: 10px;

    input {
      text-align: center;
      width: 50px;
      height: 64px;
      border: none;
      border-radius: 10px;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 52px;
      background: transparent;
      color: var(--color-black);
      // transform: rotate(-45deg); uncomment to change to diamoind input
      padding: 0px !important;
    }

    input:focus {
      outline: transparent;
    }
  }
}