.container_confirmation-later-process{
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;  
  width: 100%; 
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  min-height: calc(77vh - 120px);
 h4 {
   color: #00254A;
   font-family: Roboto;
   font-size: 18px;
   font-style: normal;
   font-weight: 700;
   line-height: 24px; /* 133.333% */
 }
 p {
    color: #363A44;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
 }
  .container_confirmation-later-process__date {
    width: 100%;
    display: flex;
    flex-direction: column;
     align-items: center;
    height: 104px;
    gap: 12px;
    border-radius: 8px;
    background:  #F7F7F7;
    padding: 16px 30px;
  }
  h1{
    color: #00254A;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 112.5% */
  }
}

.container_confirmation-later-process__footer-text {
  color: #363A44;
text-align: center;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  
}

.container_confirmation-later-process__footer-link {
  color:  #005CB9 !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline !important;
}