.rc-slider {
    position: relative;
    width: 100%;
    height: 14px;
    padding: 15px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
     .rc-slider-rail {
        position: absolute;
        width: 100%;
        height: 8px !important;
        border-radius: 90px;
        background: var(--escala-de-grises-gris-200, #E8EAED);
    }
    .rc-slider-track {
        position: absolute;
        height: 8px;
        background-color: #2D7AC7;
        border-radius: 6px;
    }    
    .rc-slider-handle{
        position: absolute;
        z-index: 1;
        width: 32px;
        height: 32px;
        margin-top: -11px;
        background-color: #005CB9;
        border: solid 7px #FFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.17) ;
        border-radius: 50%;
        cursor: pointer;
        cursor: -webkit-grab;
        cursor: grab;
        opacity: 1;
        touch-action: pan-x;
        transform: translateX(-7%) !important;
        &.rc-slider-handle-dragging {
            border-color: #FFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.17) ;  
        }
    }
}

.container_form__money {
    color: var(--color-secundario-azul-900, #00376F);
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.container_form__money-description{
    color: #00254A;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}


.alert_message {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    background: var(--escala-de-grises-gris-100-fondo, #F7F7F7);
    height: 92px;
    margin-top: 24px;
    p {
        color: var(--escala-de-grises-gris-color-de-texto, #363A44);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; 
    }
}