button {
    display: flex;
    border: transparent;
    padding: 10px 24px;
    gap: 8px;
    left: calc(50% - 172px);
    bottom: 25.35px;
    border-radius: 8px
}

button {
   &.btn-outline-dark-blue {
        &:disabled {
            cursor: not-allowed !important;
            color: var(--color-disabled-btn) !important;
            border-color:  1px solid var(--color-disabled-btn) !important;
        }
    }
}