.header-use-account-management__risk-response {
   border-radius: 0px 0px 10px 10px;
}

.container_use-account-management__risk-response {
   display: flex;
   flex-direction: column;
   gap: 32px;
   align-items: center;  
   margin-top: 48px;
   margin-left: auto;
   margin-right: auto;
   width: 344px;
   min-height: calc(85vh - 120px);
 h3 {
    color: #444;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }
 h4 {
    color: #00254A;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
 }
 p {
    color: #00254A;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  b {
    color: #00254A;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
 }

}