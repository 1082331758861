.header-retake-phone{
   border-radius: 0px 0px 10px 10px;
}

.container_retake-phone{
   display: flex;
   flex-direction: column;
   gap: 32px;
   width: 100%;
   min-height: calc(90vh - 120px);
   padding-left: 16px;
   padding-right: 16px;
   padding-top: 24px;
 h3 {
    color: #444;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }
 h4 {
    color: #00254A;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
 }
 p {
    color: #00254A;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  b {
    color: #00254A;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
 }

}