.personalization-accordion-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.personalization-accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
  color: var(--dark-blue-title) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: transparent !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-button {
  color: var(--dark-blue-title) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100% !important;
  padding-left: 0px !important;
}

.accordion-header {
  button {
    bottom: 0px !important;
  }
}

.accordion-item {
  border: none !important;
  border-bottom: 1px solid var(--disabled-link) !important;
  color: var( --dark-blue-title) !important;
}

.accordion-body {
  padding: 0px !important;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
}

.last-accordion {
  border-bottom: transparent !important;
}