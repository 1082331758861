.container__videocapturecondition {
    display: flex;
    flex-direction: column;
    align-items: center;  
    min-height: calc(80vh - 120px);
    padding: 0px 16px;

    &--error {
        display: flex;
        padding: 16px;
        align-items: flex-start;
        gap: 8px;
        margin-left: -16px;
        margin-right: -16px;
    }
}

a {
    &.btn-outline-dark-blue {
        display: flex;
        width: 100%;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--color-secundario-azul-800, #004A94);
        height: 40px;
        &:disabled {
            cursor: not-allowed !important;
            color: var(--color-disabled-btn) !important;
            border-color:  1px solid var(--color-disabled-btn) !important;
        }
    }
    > p {
        margin: 0px !important ;
    }
}