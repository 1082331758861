.container_form__title{
    color:var(--dark-blue-title)  #00254A;
    font-size: 18px;
    font-weight: 700;
}


.container_form__subtitle{
    color:var(--dark-blue-title)  #00254A;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}


.container_form__title-inputs {
    color:var(--dark-blue-title)  #00254A;
    font-size: 16px;
    font-weight: 500;
}

.container_form__title_and_image{
    color: #005CB9;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
}

.container_form__radio_title{
    color: var(--dark-blue-title)#00254A;
    font-size: 16px;
    font-weight: 500;
}


