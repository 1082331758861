$width: 250px;
$animationDuration: 1s;

.ball-loader {
  width: $width;
  height: calc($width / 3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
}

.ball-loader-ball {
  will-change: transform;
  height: calc($width / (1 * 4));
  width: calc($width / (1 * 4));
  border-radius: 50%;
  background-color: var(--loader-balls);
  display: inline-block;
  animation: grow $animationDuration ease-in-out infinite alternate;
  transform-origin: 50% 50%;

  &.ball1 {
    margin-right: calc($width / 11);
  }

  &.ball2 {
    margin-right: calc($width / 11);
    animation-delay: - $animationDuration * .33;
  }

  &.ball3 {
    animation-delay: - $animationDuration * .66;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
    background-color: var(--loader-balls);
  }

  50% {
    background-color: var(--loader-balls);
  }

  100% {
    transform: scale(.2);
    background-color: var(--loader-balls);
  }
}