.slide-but * {
    box-sizing: border-box;
  }
  .slide-but {
    height: 40px;
    border: 1px solid #8D94A4;
    min-width: 200px;
    padding: 11px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    user-select: none;
    border-radius: 8px;
    span {
      color:  #8D94A4;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; 
    }
  }
  .slide-but * {
    pointer-events: none;
    user-select: none;
  }
  .slide-but .slide-overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 2rem;
    background: #E42A23;
    transition: width 0.2s ease-out;
    will-change: width;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .slide-but .slide-overlay .slide-overlay-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .slide-but .slide-overlay .slide-caret-wrapper {
    position: absolute;
    border-radius: 8px;
    max-width: 45px;
    min-width: 0;
    right: 0;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    background: #E42A23;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    
  }
  .slide-but .slide-overlay .slide-overlay-txt {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }