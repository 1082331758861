/* Variables */
:root {
  --dark-gray: #70798E;
  --light-gray: #8D94A4;
  --light-gray-500: #5A6172;
  --dark-blue-title: #00254A;
  --dark-gray-title: #444444;
  --dark-gray-paragraph: #363A44;
  --bg-dark-blue: #00376F;
  --bg-toggle-btn-light-gray: #F7F7F7;
  --bg-toggle-btn-dark-blue: #004A94;
  --bg-toggle-btn-light-blue: #CBE1F8;
  --hr-light-gray: #E8EAED;
  --btn-bg-primary: #F68A33;
  --loader-balls: #B5D0F0;
  --disabled-link: #CBCFD6;
  --color-black: #282C33;
  --light-gray-900: #f3eeee;
  --dangerous-red: #C94444;
  --bg-color-disabled-btn: #E8EAED;
  --bg-color-enabled-btn: #F68A33;
  --color-disabled-btn: #A9AEBB;
  --color-enabled-btn: #FFFFFF;
  --green-color: #33CD5E;
  --bg-blue-light: #E2F0FF;
  --secondary-blue-0: #E5EEF8
}


/* class colors */
.dark-gray {
  color: var(--dark-gray) !important;
}

.light-gray {
  color: var(--light-gray);
}

.light-gray-500 {
  color: var(--light-gray-500);
}

.dark-blue-title {
  color: var(--dark-blue-title);
}

.dark-gray-title {
  color: var(--dark-gray-title);
}

.dark-gray-paragraph {
  color: var(--dark-gray-paragraph);
}

.bg-dark-blue {
  color: var(--bg-dark-blue);
}

.bg-toggle-btn-light-gray {
  color: var(--bg-toggle-btn-light-gray);
}

.bg-toggle-btn-light-blue {
  color: var(--bg-toggle-btn-light-blue);
}

.hr-light-gray {
  color: var(--hr-light-gray);
}

.color-dark-blue {
  color: var(--bg-toggle-btn-dark-blue);
}

.toggle-btn-dark-blue {
  color: var(--bg-toggle-btn-dark-blue);
}

.btn-outline-btn-dark-blue {
  border: 1px solid var(--bg-toggle-btn-dark-blue);
}

.orange-color {
  color: var(--btn-bg-primary);
}

.btn-bg-primary {
  background-color: var(--btn-bg-primary);
}

.dangerous-red {
  color: var(--dangerous-red);
}

.disabled-link {
  color: var(--disabled-link) !important;
}

.light-gray-900 {
  color: var(--light-gray-900);
}

.bg-blue-light {
  background-color: var(--bg-blue-light);
}