.footer {
   opacity: 1 !important;
   clear: both;
   padding-left: 16px; 
   padding-right: 16px; 
   padding-bottom: 16px;
   display:flex;
   flex-direction: column;
   gap: 24px;
}


.footer--pin__button_primary {
    border-radius: 8px;
    background-color: var(--btn-bg-primary);
    color: var(--escala-de-grises-gris-0, #FFF);
}

.footer--pin__button_primary-disabled {
    color: var(--color-disabled-btn);
    background-color: var(--bg-color-disabled-btn) !important;
  }

  .footer--pin__button_blue-disabled {
    color: var(--color-disabled-btn);
    background-color: var(--bg-color-disabled-btn) !important;
    border: 1px solid var(--color-disabled-btn) !important;
  }


.footer--pin__button_link {
    color: var(--light-gray-500);
    line-Height: 16px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
}

.footer--pin__button_secundary {
    display: flex;
    width: 344px;
    padding: 10px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--color-secundario-azul-800, #004A94);
}