.error-markdown {
    width: 100%;
    h4 {
        text-align: left;
    } 
    ol, ul, dl {
        margin-bottom: 8px;
        color: #00254A;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}